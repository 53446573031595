<template>
  <v-container fluid
    ><div v-if="!state.p_verify">
      <v-text-field type="password" label="Passwort" v-model="viewmodel.form_data.password"></v-text-field>
      <v-btn color="primary" @click.prevent="VerifyPassword">OK</v-btn>
    </div>

    <div v-if="state.p_verify">
      <div>
        <h2>Kursverwaltung</h2>
        <v-data-table :headers="viewmodel.data_tables.courses.headers" :items="viewmodel.data_tables.courses.data">
          <template v-slot:top>
            <v-form ref="form_create_or_edit_course">
              <v-toolbar flat>
                <v-switch
                  label="nur aktive Kurse zeigen"
                  v-model="state.filter_active"
                  @change="FilterCourses"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="650px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Kurs hinzufügen </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.kurs_name"
                              label="Kursname"
                              :rules="rules.generic_rules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="editedItem.kurs_typ"
                              label="Kurstyp"
                              :rules="rules.autocomplete_rules"
                              :items="kurs_typen"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.age_group"
                              label="Altersgruppe"
                              :rules="rules.generic_rules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                          ><v-col cols="12" sm="12" md="6" lg="6" xl="6"
                            ><v-file-input
                              label="Flyer/Infomaterial (png)"
                              truncate-length="15"
                              accept="image/png"
                              v-model="viewmodel.form_data.flyer"
                            ></v-file-input></v-col
                        ></v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.date_from"
                                  label="Datum Kursbeginn"
                                  hint="Achtung, JJJJ-MM-TT"
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="rules.autocomplete_rules"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="editedItem.date_from"
                                min="2021-01-01"
                                max="2030-12-31"
                                @change="save_date"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6">
                            <v-menu
                              ref="menu_to"
                              v-model="menu_to"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.date_to"
                                  label="Datum Kursende"
                                  hint="Achtung, JJJJ-MM-TT"
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="rules.autocomplete_rules"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="editedItem.date_to"
                                min="2021-01-01"
                                max="2030-12-31"
                                @change="save_date_to"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-checkbox
                              v-model="editedItem.is_active"
                              label="Kurs auf Hauptseite anzeigen"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.duration"
                              label="Kursdauer (Tage)"
                              :rules="rules.duration_rules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              v-model="editedItem.capacity"
                              label="Maximale Teilnehmerzahl"
                              :rules="rules.autocomplete_rules"
                              :items="MaxCapacity"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="error" @click="close"> Abbrechen </v-btn>
                      <v-btn color="success" @click="save"> Speichern </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </v-form>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
            <vue-csv-downloader
              class="text-decoration-none"
              :fields="viewmodel.data_tables.teilnehmer.fields"
              :data="viewmodel.data_tables.teilnehmer.data"
              :download-name="'Teilnehmerliste ' + item.kurs_name + '.csv'"
              ><v-icon
                small
                class="mr-2"
                @click="DoAuswertungTeilnehmer(item)"
                v-if="Number(item.free_slots) < Number(item.capacity)"
              >
                mdi-clipboard-list-outline
              </v-icon>
            </vue-csv-downloader>
            <v-icon
              small
              @click="DoAuswertungTeilnehmerPDF(item)"
              v-if="Number(item.free_slots) < Number(item.capacity)"
              >mdi-file-outline</v-icon
            >
          </template>
          <template v-slot:item.description="{ item }">
            <a :href="item.description" target="blank" v-if="item.description">Details</a>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog3" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" v-if="1 === 2"> Click Me </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline grey lighten-2"> Details </v-card-title>

            <v-card-text class="mt-4">
              {{ dialog_content }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="dialog3 = false"> Schließen </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-alert
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="viewmodel.alert.alert_type"
          elevation="2"
          v-model="viewmodel.alert.show"
          >{{ viewmodel.alert.response }}</v-alert
        >
        <v-row
          ><v-col cols="12">
            <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="this.state.is_loading">
            </v-progress-linear></v-col
        ></v-row>
      </div>
      <div>
        <h2>Buchungsanfragen</h2>
        <v-card flat max-width="300">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-card>

        <v-data-table
          :headers="viewmodel.data_tables.requests.headers"
          :items="viewmodel.data_tables.requests.data"
          :search="search"
        >
          <template v-slot:top>
            <v-form ref="form_create_or_edit_course">
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog2" max-width="650px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Buchungsdetails</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.vorgang_id" label="AnfragenID"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-autocomplete
                              v-model="editedItem2.kurs_id"
                              label="Kurs/Veranstaltung"
                              :items="viewmodel.data_tables.courses.data"
                              item-text="kurs_name"
                              item-value="id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field v-model="editedItem2.kurs_typ" label="Kurstyp"> </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field v-model="editedItem2.date" label="Wunschtermin"></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field v-model="editedItem2.created_at" label="Anfragedatum"> </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.name_parent" label="Name Elternteil"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem2.address_parent"
                              label="Anschrift Elternteil"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.plz_parent" label="PLZ Elternteil"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.city_parent" label="Stadt Elternteil"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem2.phone_parent"
                              label="Tel. Nummer Elternteil"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.mail_parent" label="E-Mail Elternteil"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.name_child" label="Name Kind"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.address_child" label="Anschrift Kind"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.plz_child" label="PLZ Kind"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.city_child" label="Stadt Kind"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.age_child" label="Alter Kind"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea v-model="editedItem2.comment" label="Kommentar"></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem2.gutschein_code"
                              label="Gutscheincode"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem2.status" label="Status" readonly></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="success" @click="SaveRequest"> Speichern </v-btn>
                      <v-btn
                        color="success"
                        @click="ApproveRequest"
                        :disabled="
                          editedItem2.status === 'Genehmigt' ||
                          editedItem2.status === 'Gelöscht' ||
                          editedItem2.status === 'Neu' ||
                          editedItem2.status === 'Storniert'
                        "
                      >
                        Anfrage genehmigen
                      </v-btn>
                      <v-btn
                        color="error"
                        @click="DeleteRequest"
                        :disabled="
                          editedItem2.status === 'Gelöscht' ||
                          editedItem2.status === 'Storniert' ||
                          editedItem2.status === 'Bestätigt'
                        "
                      >
                        Anfrage löschen
                      </v-btn>
                    </v-card-actions>
                    <v-card-text>
                      <v-form ref="form_cancel_request" v-if="editedItem2.status !== 'Storniert'">
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-textarea
                                v-model="viewmodel.form_data.reason"
                                label="Grund der Stornierung"
                                counter="1000"
                                :rules="rules.cancel_rules"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="mt-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        @click="CancelRequest"
                        :disabled="editedItem2.status === 'Storniert' || editedItem2.status === 'Gelöscht'"
                      >
                        Anfrage stornieren
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions class="mt-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click.prevent="SendConfirmationMail"
                        :disabled="
                          editedItem2.status === 'Storniert' ||
                          editedItem2.status === 'Gelöscht' ||
                          editedItem2.status === 'Genehmigt'
                        "
                      >
                        E-Mail erneut versenden
                      </v-btn>
                      <v-btn color="error" class="ml-4" @click="close2"> Schließen </v-btn>
                    </v-card-actions>
                    <v-card-text>
                      <v-row
                        ><v-col cols="12">
                          <v-progress-linear
                            color="primary"
                            class="mt-4"
                            :size="100"
                            indeterminate
                            v-if="state.is_loading_conf_mail"
                          >
                          </v-progress-linear></v-col
                      ></v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-alert
                            border="top"
                            transition="fade-transition"
                            dismissible
                            colored-border
                            :type="viewmodel.alert_conf_mail.alert_type"
                            elevation="2"
                            v-model="viewmodel.alert_conf_mail.show"
                            >{{ viewmodel.alert_conf_mail.response }}</v-alert
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-row
                        ><v-col cols="12">
                          <v-progress-linear
                            color="primary"
                            class="mt-4"
                            :size="100"
                            indeterminate
                            v-if="state.is_loading2"
                          >
                          </v-progress-linear></v-col
                      ></v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </v-form>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="editItem2(item)"> mdi-pencil </v-icon>
          </template>
        </v-data-table>
        <div class="d-flex justify-content-end">
          <v-spacer></v-spacer>
          <v-btn class="mb-4 mt-4 mr-4" color="primary" @click.prevent="GetAllRequests">Aktualisieren</v-btn>
        </div>
        <v-alert
          border="top"
          transition="fade-transition"
          dismissible
          colored-border
          :type="viewmodel.alert2.alert_type"
          elevation="2"
          v-model="viewmodel.alert2.show"
          >{{ viewmodel.alert2.response }}</v-alert
        >
        <v-row
          ><v-col cols="12">
            <v-progress-linear color="primary" class="mt-4" :size="100" indeterminate v-if="state.is_loading2">
            </v-progress-linear></v-col
        ></v-row>
      </div>
      <div>
        <h2>Gutscheincodes</h2>
        <v-data-table :headers="viewmodel.data_tables.codes.headers" :items="viewmodel.data_tables.codes.data">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <div class="d-flex flex-direction-row">
                <v-autocomplete
                  label="Anzahl"
                  :items="Gutscheincodes"
                  class="mr-4"
                  v-model="viewmodel.form_data.number_of_codes"
                ></v-autocomplete>
                <v-btn color="primary" @click.prevent="CreateCode">Gutscheine erzeugen</v-btn>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="editItem2(item)"> mdi-pencil </v-icon>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-container>
</template>

<script>
const axios = require('axios');

import VueCsvDownloader from 'vue-csv-downloader';
import download from 'downloadjs';

export default {
  name: 'Admin',
  components: { VueCsvDownloader },
  data: () => ({
    search: '',
    dialog3: false,
    dialog_content: '',
    menu: false,
    menu_to: false,
    kurs_typen: ['Ferienspiele', 'Schwimmkurs Kinder', 'Schwimmkurs Erwachsene'],
    state: {
      p_verify: false,
      is_loading: false,
      is_loading2: false,
      is_loading_conf_mail: false,
      filter_active: false,
    },
    rules: {
      generic_rules: [
        (v) => !!v || 'Pflichtfeld',
        (v) => (v && v.length <= 45) || 'Maximale Anzahl an Zeichen überschritten',
      ],
      textarea_rules: [
        (v) => !!v || 'Pflichtfeld',
        (v) => (v && v.length <= 1000) || 'Maximale Anzahl an Zeichen überschritten',
      ],
      duration_rules: [(v) => v > 0 || 'Kursdauer kann nicht negativ sein'],
      mail_rules: [(v) => !!v || 'Pflichtfeld', (v) => /.+@.+\..+/.test(v) || 'Keine gültige E-Mail Adresse'],
      autocomplete_rules: [(v) => !!v || 'Pflichtfeld'],
      cancel_rules: [(v) => !!v || 'Pflichtfeld'],
    },
    viewmodel: {
      alert: {
        show: false,
        alert_type: 'info',
        response: null,
      },
      alert2: {
        show: false,
        alert_type: 'info',
        response: null,
      },
      alert_conf_mail: {
        show: false,
        alert_type: 'info',
        response: null,
      },
      form_data: {
        password: null,
        status: ['Neu', 'Bestätigt', 'Genehmigt', 'Storniert', 'Gelöscht'],
        reason: null,
        flyer: [],
        number_of_codes: null,
      },
      data_tables: {
        teilnehmer: {
          data: [],
          fields: [
            'Kurs',
            'Kurstyp',
            'Kursbeginn',
            'Kursende',
            'Kursdauer',
            'Name_Elternteil',
            'PLZ_Elternteil',
            'Wohnort_Elternteil',
            'Mail_Elternteil',
            'Telefon_Elternteil',
            'Name_Kind',
            'Anschrift_Kind',
            'PLZ_Kind',
            'Wohnort_Kind',
            'Alter_Kind',
            'Kommentar',
          ],
        },
        courses: {
          data: [],
          headers: [
            {
              text: 'Kurs/Veranstaltung',
              align: 'start',
              value: 'kurs_name',
            },
            {
              text: 'Kurstyp',
              align: 'start',
              value: 'kurs_typ',
            },
            {
              text: 'aktiv',
              align: 'start',
              value: 'is_active',
            },
            {
              text: 'Beschreibung',
              align: 'start',
              value: 'description',
            },
            {
              text: 'Altersgruppe',
              align: 'start',
              value: 'age_group',
            },
            {
              text: 'Beginn',
              align: 'start',
              value: 'date_from',
            },
            {
              text: 'Ende',
              align: 'start',
              value: 'date_to',
            },
            {
              text: 'Dauer (Tage)',
              align: 'start',
              value: 'duration',
            },
            {
              text: 'Freie Plätze',
              align: 'start',
              value: 'free_slots',
            },
            {
              text: 'Maximale Teilnehmerzahl',
              align: 'start',
              value: 'capacity',
            },
            {
              text: 'Aktion',
              align: 'center',
              value: 'action',
              width: '150',
            },
          ],
        },
        requests: {
          data: [],
          headers: [
            {
              text: 'AnfragenID',
              align: 'start',
              value: 'vorgang_id',
            },
            {
              text: 'Name',
              align: 'start',
              value: 'name_parent',
            },
            {
              text: 'Kurs/Veranstaltung',
              align: 'start',
              value: 'kurs_name',
            },
            {
              text: 'Kurstyp',
              align: 'start',
              value: 'kurs_typ',
            },
            {
              text: 'Wunschtermin',
              align: 'start',
              value: 'date',
            },
            {
              text: 'Anfragedatum',
              align: 'start',
              value: 'created_at',
            },
            {
              text: 'Status',
              align: 'start',
              value: 'status',
            },
            {
              text: 'Aktion',
              align: 'center',
              value: 'action',
            },
          ],
        },
        codes: {
          data: [],
          headers: [
            {
              text: 'Gutscheincode',
              align: 'start',
              value: 'code',
            },
            {
              text: 'Benutzt',
              align: 'start',
              value: 'used_at',
            },
            {
              text: 'VorgangID',
              align: 'start',
              value: 'vorgang_id',
            },
          ],
        },
      },
    },
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      id: null,
      kurs_name: null,
      kurs_typ: null,
      description: null,
      age_group: null,
      date_from: null,
      date_to: null,
      is_active: true,
      duration: null,
      capacity: null,
      free_slots: null,
    },
    dialog2: false,
    editedIndex2: -1,
    editedItem2: {
      id: null,
      vorgang_id: null,
      kurs_name: null,
      kurs_id: null,
      kurs_typ: null,
      date: null,
      name_parent: null,
      address_parent: null,
      plz_parent: null,
      city_parent: null,
      mail_parent: null,
      phone_parent: null,
      name_child: null,
      address_child: null,
      plz_child: null,
      city_child: null,
      age_child: null,
      comment: null,
      gutschein_code: null,
      created_at: null,
      status: null,
    },
    defaultItem2: {
      id: null,
      vorgang_id: null,
      kurs_name: null,
      kurs_typ: null,
      date: null,
      name_parent: null,
      address_parent: null,
      plz_parent: null,
      city_parent: null,
      mail_parent: null,
      phone_parent: null,
      address_child: null,
      plz_child: null,
      city_child: null,
      age_child: null,
      comment: null,
      created_at: null,
      status: null,
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Neuer Kurs' : 'Kurs bearbeiten';
    },
    IsLoading() {
      return this.state.is_loading;
    },
    MaxCapacity() {
      return Array.from({ length: 101 }, (x, i) => i + 1);
    },
    Gutscheincodes() {
      return Array.from({ length: 500 }, (x, i) => i + 1);
    },
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    menu(val) {
      // eslint-disable-next-line no-unused-expressions, no-return-assign
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  methods: {
    SendConfirmationMail() {
      const data = this.editedItem2;
      this.state.is_loading_conf_mail = true;
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/request_send_confirmation_mail.php', data)
        .then((response) => {
          this.state.is_loading_conf_mail = false;
          this.viewmodel.alert_conf_mail.show = true;
          this.viewmodel.alert_conf_mail.alert_type = 'success';
          this.viewmodel.alert_conf_mail.response = response.data.message;
        })
        .catch((error) => {
          this.state.is_loading_conf_mail = false;
          this.viewmodel.alert_conf_mail.show = true;
          this.viewmodel.alert_conf_mail.type = 'error';
          this.viewmodel.alert_conf_mail.response = error.data.message;
        });
    },
    SaveRequest() {
      const data = this.editedItem2;
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/request_update_request.php', data)
        .then(() => {
          this.dialog2 = false;
          this.GetAllRequests();
          this.GetAllCourses();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ShowModal(item) {
      this.dialog3 = true;
      this.dialog_content = item.description;
    },
    VerifyPassword() {
      const data = {
        password: this.viewmodel.form_data.password,
      };
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/verify.php', data)
        .then(() => {
          this.state.p_verify = true;
        })
        .catch(() => {
          this.state.p_verify = false;
        });
    },
    FilterCourses() {
      const filtered_data = this.viewmodel.data_tables.courses.data.filter((result) => result.is_active === 'Ja');
      if (this.state.filter_active) {
        this.viewmodel.data_tables.courses.data = filtered_data;
      } else {
        this.GetAllCourses();
      }
    },
    DoAuswertungTeilnehmer(item) {
      axios.post(`${process.env.VUE_APP_APIBASEURL}/get_teilnehmer.php`, item).then((response) => {
        this.viewmodel.data_tables.teilnehmer.data = response.data.result;
      });
    },
    DoAuswertungTeilnehmerPDF(item) {
      console.log(item);
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/get_teilnehmer_pdf.php', item, { responseType: 'blob' })
        .then((response) => {
          download(new Blob([response.data]), 'Teilnehmerliste_' + item.kurs_name + '.pdf', 'application/pdf');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetAllCourses() {
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/get_all_courses_admin.php')
        .then((response) => {
          this.viewmodel.data_tables.courses.data = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetAllRequests() {
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/get_all_requests_admin.php')
        .then((response) => {
          this.viewmodel.data_tables.requests.data = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetAllCodes() {
      axios
        .post(process.env.VUE_APP_APIBASEURL + '/get_all_codes.php')
        .then((response) => {
          this.viewmodel.data_tables.codes.data = response.data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    CreateCode() {
      if (this.viewmodel.form_data.number_of_codes > 0) {
        const data = this.GetCodeArray();
        axios
          .post(process.env.VUE_APP_APIBASEURL + '/code_create_code.php', data, { responseType: 'blob' })
          .then((response) => {
            this.GetAllCodes();
            download(new Blob([response.data]), 'Download.pdf', 'application/pdf');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    GetCodeArray() {
      let code_array = [];
      for (let i = 0; i < this.viewmodel.form_data.number_of_codes; i++) {
        code_array[i] =
          Math.floor(Math.random() * 10000 + 10000)
            .toString()
            .substring(1) +
          '-' +
          Math.floor(Math.random() * 10000 + 10000)
            .toString()
            .substring(1) +
          '-' +
          Math.floor(Math.random() * 10000 + 10000)
            .toString()
            .substring(1);
      }
      this.viewmodel.form_data.code_array = code_array;
      return code_array;
    },
    // GetCourseDuration() {
    //   if (this.editedItem.date_from && this.editedItem.date_to) {
    //     const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    //     const date1 = new Date(this.editedItem.date_from);
    //     const date2 = new Date(this.editedItem.date_to);
    //     const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    //     const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    //     this.editedItem.duration = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    //     if (this.editedItem.duration === 0) {
    //       this.editedItem.duration = 1;
    //     }
    //   }
    //   return 0;
    // },
    CreateCourse() {
      this.state.is_loading = true;
      const data = this.editedItem;
      const json = JSON.stringify(data);
      const blob = new Blob([json], {
        type: 'application/json',
      });
      const formData = new FormData();
      formData.append('form_data', blob);
      formData.append('file', this.viewmodel.form_data.flyer);
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/create_course.php`, formData)
        .then((response) => {
          this.viewmodel.alert.show = true;
          this.viewmodel.alert.alert_type = 'success';
          this.viewmodel.alert.response = response.data.message;
          this.$refs.form_create_or_edit_course.reset();
          this.viewmodel.form_data.flyer = [];
          this.editedItem.is_active = true;
          this.state.is_loading = false;
          this.GetAllCourses();
        })
        .catch((error) => {
          this.state.is_loading = false;
          this.viewmodel.alert.show = true;
          this.viewmodel.alert.response = error.response.data.message;
          this.viewmodel.alert.alert_type = 'error';
          this.editedItem.is_active = true;
        });
    },
    UpdateCourse() {
      this.state.is_loading = true;
      const data = this.editedItem;
      const json = JSON.stringify(data);
      const blob = new Blob([json], {
        type: 'application/json',
      });
      const formData = new FormData();
      formData.append('form_data', blob);
      formData.append('file', this.viewmodel.form_data.flyer);
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/update_course.php`, formData)
        .then((response) => {
          this.state.is_loading = false;
          this.viewmodel.alert.show = true;
          this.viewmodel.alert.alert_type = 'success';
          this.viewmodel.alert.response = response.data.message;
          this.viewmodel.form_data.flyer = [];
          this.GetAllCourses();
        })
        .catch((error) => {
          this.state.is_loading = false;
          this.viewmodel.alert.show = true;
          this.viewmodel.alert.response = error.response.data.message;
          this.viewmodel.alert.alert_type = 'error';
        });
    },

    editItem(item) {
      this.editedIndex = this.viewmodel.data_tables.courses.data.indexOf(item);
      this.editedItem = { ...item };
      this.editedItem.capacity = Number(this.editedItem.capacity);
      if (this.editedItem.is_active === 'Ja') {
        this.editedItem.is_active = true;
      } else {
        this.editedItem.is_active = false;
      }
      this.editedItem.date_from = this.StringToDate(this.editedItem.date_from);
      this.editedItem.date_to = this.StringToDate(this.editedItem.date_to);
      this.dialog = true;
    },
    editItem2(item) {
      this.editedIndex2 = this.viewmodel.data_tables.requests.data.indexOf(item);
      this.editedItem2 = { ...item };
      this.dialog2 = true;
    },

    StringToDate(date_as_string) {
      const parts = date_as_string.split('.');
      parts[0] = parseInt(parts[0]);
      parts[1] = parseInt(parts[1]);
      parts[2] = parseInt(parts[2]);
      return parts[2] + '-' + parts[1] + '-' + parts[0];
    },
    close() {
      this.dialog = false;
      this.$refs.form_create_or_edit_course.reset();
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    close2() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.editedItem2 = { ...this.defaultItem2 };
        this.editedIndex2 = -1;
      });
    },

    save() {
      if (this.editedIndex > -1 && this.$refs.form_create_or_edit_course.validate()) {
        this.UpdateCourse();
        this.close();
      } else if (this.$refs.form_create_or_edit_course.validate()) {
        this.CreateCourse();
        this.close();
      }
    },
    save_date(date) {
      this.$refs.menu.save(date);
      // this.GetCourseDuration();
    },
    save_date_to(date) {
      this.$refs.menu_to.save(date);
      // this.GetCourseDuration();
    },
    ApproveRequest() {
      this.state.is_loading2 = true;
      const data = this.editedItem2;
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/approve_request.php`, {
          data,
        })
        .then((response) => {
          this.state.is_loading2 = false;
          this.viewmodel.alert2.show = true;
          this.viewmodel.alert2.alert_type = 'success';
          this.viewmodel.alert2.response = response.data.message;
          this.GetAllCourses();
          this.GetAllRequests();
          this.close2();
        })
        .catch((error) => {
          this.state.is_loading2 = false;
          this.viewmodel.alert2.show = true;
          this.viewmodel.alert2.response = error.response.data.message;
          this.viewmodel.alert2.alert_type = 'error';
        });
    },
    DeleteRequest() {
      this.state.is_loading2 = true;
      const data = this.editedItem2;
      axios
        .post(`${process.env.VUE_APP_APIBASEURL}/delete_request.php`, {
          data,
        })
        .then((response) => {
          this.state.is_loading2 = false;
          this.viewmodel.alert2.show = true;
          this.viewmodel.alert2.alert_type = 'success';
          this.viewmodel.alert2.response = response.data.message;
          this.GetAllCourses();
          this.GetAllRequests();
          this.close2();
        })
        .catch((error) => {
          this.state.is_loading2 = false;
          this.viewmodel.alert2.show = true;
          this.viewmodel.alert2.response = error.response.data.message;
          this.viewmodel.alert2.alert_type = 'error';
        });
    },
    CancelRequest() {
      if (this.$refs.form_cancel_request.validate()) {
        this.state.is_loading2 = true;
        let data = this.editedItem2;
        data.reason = this.viewmodel.form_data.reason;
        axios
          .post(`${process.env.VUE_APP_APIBASEURL}/cancel_request.php`, {
            data,
          })
          .then((response) => {
            this.state.is_loading2 = false;
            this.viewmodel.alert2.show = true;
            this.viewmodel.alert2.alert_type = 'success';
            this.viewmodel.alert2.response = response.data.message;
            this.GetAllCourses();
            this.GetAllRequests();
            this.close2();
          })
          .catch((error) => {
            this.state.is_loading2 = false;
            this.viewmodel.alert2.show = true;
            this.viewmodel.alert2.response = error.response.data.message;
            this.viewmodel.alert2.alert_type = 'error';
          });
      }
    },
  },
  mounted() {
    this.GetAllCourses();
    this.GetAllRequests();
    this.GetAllCodes();
  },
};
</script>
